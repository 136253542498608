import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  body {
    -webkit-font-smoothing: antialiased;
    background: #dee8fd;
  }
  body, input, button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }
  button {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      filter: brightness(1.5)
    }
  }
  a {
    text-decoration: none;
   a:visited {
     text-decoration: none;
   }
  }

  html {
    @media (max-width: 1080px) {
      font-size: 97.75%;
    }
    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  /* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d2d2d2;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a2a2a2;
}
`
