import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { BiArrowBack } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import { MdContentCopy } from 'react-icons/md'

import {
  Container,
  CloseButton,
  HeaderCenter,
  HeaderCenterPass,
  ButtonCopy,
  UserPassContainer,
} from './styles'

interface HeaderDialogProps {
  close(): void
  user: string
  password: string
  openSnack: () => void
}

export const HeaderDialog: React.FC<HeaderDialogProps> = ({
  close,
  user,
  password,
  openSnack,
}: HeaderDialogProps) => {
  return (
    <Container>
      <CloseButton>
        <button onClick={close}>
          <BiArrowBack size={25} />
          <span>Voltar</span>
        </button>
      </CloseButton>
      <HeaderCenter>
        <h4>Informe Usuário e Senha na tela abaixo</h4>
        <HeaderCenterPass>
          <UserPassContainer>
            <CopyToClipboard text={user}>
              <ButtonCopy onClick={openSnack}>
                <b>Usuário:&nbsp;</b>
                {user}&nbsp;
                <MdContentCopy size={20} />
              </ButtonCopy>
            </CopyToClipboard>
          </UserPassContainer>
          <UserPassContainer>
            <CopyToClipboard text={password}>
              <ButtonCopy onClick={openSnack}>
                <b>Senha:&nbsp;</b>
                {password}&nbsp;
                <MdContentCopy size={20} />
              </ButtonCopy>
            </CopyToClipboard>
          </UserPassContainer>
        </HeaderCenterPass>
      </HeaderCenter>
      <CloseButton>
        <button onClick={close}>
          <AiOutlineClose size={25} />
        </button>
      </CloseButton>
    </Container>
  )
}
