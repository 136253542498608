import styled from 'styled-components'
import BgImg from '../../assets/bg-right.jpg'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;

  @media (prefers-reduced-motion: no-preference) {
    .logo-spin {
      animation: logo-spin infinite 1s linear;
    }
  }

  @keyframes logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  > h4 {
    color: ${props => props.theme.title};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
  padding: 1rem;

  form {
    display: flex;
    flex-direction: column;

    width: 350px;

    > h5 {
      margin: 16px 0;
      text-align: center;
    }
  }
`

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const BgRight = styled.div`
  flex: 2;
  background: url(${BgImg}) no-repeat center;
  background-size: cover;
  opacity: 0.5;
`

export const ErrorContainer = styled.div`
  display: flex;
  place-content: center;

  small {
    color: ${props => props.theme.colors.red};
    margin: 0 8px;
    display: flex;
    align-items: center;
  }

  > svg {
    color: ${props => props.theme.colors.red};
  }
`

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${props => props.theme.colors.purple};

  > svg {
    margin-right: 8px;
  }
`

export const LogoSpinner = styled.img`
  height: 25px;
  margin-right: 5px;
`

export const IFrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`
