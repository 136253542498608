import React from 'react'
import GlobalStyles from './styles/GlobalStyles'

import { Routes } from './routes'

export const App: React.FC = () => {
  return (
    <>
      <Routes />
      <GlobalStyles />
    </>
  )
}
