import styled from 'styled-components'

export const Container = styled.button`
  background-image: linear-gradient(
    to bottom right,
    ${props => props.theme.colors.blue},
    ${props => props.theme.colors.purple}
  );

  color: #fff;

  border: 0;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  margin: 8px 0;
`
