import styled, { css } from 'styled-components'

interface IContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
}

export const Container = styled.div<IContainerProps>`
  height: 42px;
  margin: 6px 0;

  display: flex;
  align-items: center;

  border: solid 2px ${props => props.theme.colors.purple};
  border-radius: 8px;
  input {
    width: 100%;
    padding: 0 8px;
    margin-left: 8px;
    border: 0;
    flex: 1;
    background: transparent;

    ::placeholder {
      color: #828282;
    }
  }

  .s-icon {
    color: #fff;
    background-image: linear-gradient(
      to bottom right,
      ${props => props.theme.colors.purple},
      ${props => props.theme.colors.blue}
    );
    height: 100%;
    width: 35px;
    padding: 8px;
    border-radius: 2.5px;
  }

  .error-icon {
    background: transparent;
    color: transparent;
    margin-right: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: ${props => props.theme.colors.red};

      .s-icon {
        background: ${props => props.theme.colors.red};
      }

      .error-icon {
        color: ${props => props.theme.colors.red};
      }
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: ${props => props.theme.colors.light_blue};
      color: ${props => props.theme.colors.light_blue};
    `}

  ${props =>
    props.isFilled &&
    css`
      border-color: ${props => props.theme.colors.light_blue};
      color: ${props => props.theme.colors.light_blue};

      .s-icon {
        background: ${props => props.theme.colors.light_blue};
      }

      .error-icon {
        color: transparent;
      }
    `}
`
