import styled from 'styled-components'

export const Container = styled.div``

export const EtiquetaContainer = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: center;
`

export const LinkEmail = styled.h4`
  color: ${props => props.theme.colors.blue};
  font-weight: bold;
`

export const Title = styled.h5`
  color: ${props => props.theme.colors.red};
  margin-bottom: 0.5rem;
`
