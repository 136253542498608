import React from 'react'

import EtiquetaImg from '../../assets/etiqueta.png'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Container, EtiquetaContainer, LinkEmail, Title } from './styles'

const DialogMaterial: React.FC<DialogProps> = ({ children, ...rest }) => {
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: 'flex',
          justifyContent: 'center',
          scrollbarColor: 'pink',
        }}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        {...rest}
      >
        <DialogContent
          style={{
            scrollbarColor: 'yellow',
	    textAlign: 'center',
            fontSize: '16px',
          }}
        >
           <Title>
              <b>*ATENÇÃO*</b>
           </Title>
           <DialogContentText id="alert-dialog-description">
             <p style={{ fontSize: '13px' }}>
                Para acessar o resultado de exame laboratorial pelo nosso site
                {','}
                <br />
                dê um OK e prossiga com:
                <br />
                <br />
                <li>Número do atendimento hospitalar do paciente</li>
                <li>Data de Nascimento do paciente</li>
             </p>
             <EtiquetaContainer>
               <img src={EtiquetaImg} alt="etiqueta" />
             </EtiquetaContainer>
             <br />
           </DialogContentText>
        </DialogContent>
        <DialogActions>{children}</DialogActions>
      </Dialog>
    </Container>
  )
}

export default DialogMaterial
