export default {
  title: 'sj',

  colors: {
    blue: '#005A54',
    light_blue: '#12ADAA',
    purple: '#1D5A56',
    black: '#1b1b1b',
    red: '#c53040',
  },
}
