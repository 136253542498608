export default {
  title: 'pm',

  colors: {
    blue: '#0E3469',
    light_blue: '#4073E0',
    purple: '#0E3469',
    black: '#1b1b1b',
    red: '#c53040',
  },
}
