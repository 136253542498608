import styled, { keyframes } from 'styled-components'

const toBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute !important;
  width: 100%;
  padding: 16px;
  background: ${props => props.theme.colors.light_blue};
  color: #fff;
  height: 8%;
  align-items: center;

  animation: ${toBottom} 0.5s;

  button {
    background: transparent;
    border: 0;
  }

  svg {
    color: #fff;
  }
`

export const CloseButton = styled.div`
  button {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  span {
    margin: 0 0.5rem;
    font-size: 1.2rem;
  }
`

export const HeaderCenter = styled.div`
  justify-content: center;
  align-items: center;

  h4 {
    text-align: center;
    margin: 0.1rem 0;
  }
`

export const HeaderCenterPass = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const ConteinerFrame = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonCopy = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`

export const UserPassContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 0.5px #fff;
  border-radius: 8px;
  padding: 0.25rem;
  margin: 0.2rem 1rem;
  box-shadow: 4px 4px 4px ${props => props.theme.colors.blue};

  &:hover {
    filter: brightness(1.4);
  }
`
