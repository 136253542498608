/* eslint-disable prettier/prettier */
import React, { useCallback, useRef, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import { MdDateRange } from 'react-icons/md'
import { BiErrorCircle, BiLoaderAlt } from 'react-icons/bi'
import { useLocation } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
// import Iframe from 'react-iframe'

import Input from '../../components/Input'
import Button from '../../components/Button'
import { HeaderDialog } from '../../components/HeaderDialog'
import DialogMaterial from '../../components/DialogMaterial'
import { api } from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import LogoImgSJ from '../../assets/logo-sj.png'
import LogoImgPM from '../../assets/logo_promatre.png'
import LogoImgSM from '../../assets/logo-sm.png'
import LogoImgHMSJ from '../../assets/logo-bg-transp.png'
import sj from '../../styles/themes/sj'
import sm from '../../styles/themes/sm'
import pm from '../../styles/themes/pm'
import hmsj from '../../styles/themes/hmsj'
import {
  Container,
  Content,
  ImgContainer,
  BgRight,
  ErrorContainer,
  Spinner,
  IFrameContainer,
} from './styles'

interface IFormValues {
  atendimento: string
  nascimento: string
}

export const Main: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(true)
  const [errorMsg, setErrorMsg] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [formValue, setFormValue] = useState('')
  const [showHeader, setShowHeader] = useState(false)
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [snackOpen, setSnackOpen] = useState(false)
  const [emp, setEmp] = useState<string | undefined>('')
  const formRef = useRef<FormHandles>(null)
  const formRef2 = useRef<HTMLFormElement>(null)

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const empresa = Number(query.get('empresa'))

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  function logoEmpresa() {
    if (empresa === 1) {
      return LogoImgSJ
    }
    if (empresa === 2) {
      return LogoImgPM
    }
    if (empresa === 13) {
      return LogoImgSM
    } else {
      return LogoImgHMSJ
    }
  }

  function themeEmpresa() {
    if (empresa === 1) {
      return sj
    }
    if (empresa === 2) {
      return pm
    }
    if (empresa === 13) {
      return sm
    } else {
      return hmsj
    }
  }

  function empresaL(empresa: string | undefined) {
    if (empresa === '1') {
      return 'SJ'
    }
    if (empresa === '2') {
      return 'PM'
    }
    if (empresa === '13') {
      return 'SM'
    }
  }

  function handleNewForm(empresa: string | undefined) {
    const lar_janela = window.innerWidth
    const alt_janela = window.innerHeight

    window.open(
      // `http://10.20.0.92/MATRIXNET${empresaL(empresa)}/wfrmLogin.aspx`,
      `http://200.211.134.5:8102/MATRIXNET${empresaL(empresa)}/wfrmLogin.aspx`,
      '_blank',
      'toolbar=no, scrollbars=no,resizable=no, top=' +
      (lar_janela + 500) +
      ', width=' +
      (lar_janela - 20) +
      ', height=' +
      (alt_janela - 150),
    )
  }

  // function frameUrl(): any {
  //   if (emp === '1') {
  //     return 'http://10.20.0.92/MATRIXNETSJ/wfrmLogin.aspx'
  //   }
  //   if (emp === '2') {
  //     return 'http://10.20.0.92/MATRIXNETPM/wfrmLogin.aspx'
  //   }
  //   if (emp === '13') {
  //     return 'http://10.20.0.92/MATRIXNETSM/wfrmLogin.aspx'
  //   }
  // }

  function handleHeaderDialog() {
    setShowHeader(false)
  }

  const handlePostForm = () => {
    formRef2.current?.submit()
  }

  const handleSubmit = useCallback(async (values: IFormValues) => {
    try {
      setErrorMsg(false)
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        atendimento: Yup.string().required(
          'Número de atendimento é obrigatório',
        ),
        nascimento: Yup.string().required('Data de nascimento é obrigatório'),
      })

      await schema.validate(values, {
        abortEarly: false,
      })

      setSpinner(true)
      await api
        .post('/resultado-exames', {
          atendimento: values.atendimento,
          nascimento: values.nascimento,
        })
        .then(res => {
          const parser = new DOMParser()
          const xmlResponse = parser.parseFromString(res.data, 'text/xml')
          const xmlResult = xmlResponse.querySelector('DescricaoMensagem')
            ?.innerHTML

          if (xmlResult === 'NI') {
            setSpinner(false)
            alert(
              'Exame não encontrado. Verifique se o Número de atendimento e a Data de nascimento estão corretos.',
            )

            return
          }

          if (xmlResult === 'AA') {
            setSpinner(false)
            postPedido(values.atendimento)
            postPedidoL(values.atendimento)
          } else {
            setFormValue(values.atendimento)
            handlePostForm()
          }

          setSpinner(false)
        })
    } catch (err) {
      console.log(err)
      setErrorMsg(true)
      setSpinner(false)
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current?.setErrors(errors)
      } else {
        alert(
          'Serviço temporariamente indisponível, tente novamente mais tarde.',
        )
        setErrorMsg(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function postPedidoL(atendimento: string) {
    await api.post('/resultado-exames/pedido', { atendimento }).then(res => {
      const parser = new DOMParser()
      const xmlResponse = parser.parseFromString(res.data, 'text/xml')
      const xmlEmpresa = xmlResponse.querySelector('CodigoEmpresa')
        ?.innerHTML
      alert('ATENÇÃO: Copie ou digite o Usuário e Senha gerados, e insira na tela que se abrirá.')

      handleNewForm(xmlEmpresa)
    })

  }

  async function postPedido(atendimento: string) {
    await api.post('/resultado-exames/pedido', { atendimento }).then(res => {
      const parser = new DOMParser()
      const xmlResponse = parser.parseFromString(res.data, 'text/xml')
      const xmlEmpresa = xmlResponse.querySelector('CodigoEmpresa')?.innerHTML
      const atendimento = xmlResponse.querySelector('DescricaoMensagem')
        ?.innerHTML

      if (atendimento === 'NAO') {
        alert('Não existe resultado de exames para esse atendimento.')
        return
      }

      setShowHeader(true)
      postPedidoAuth(atendimento?.substring(1), xmlEmpresa)
    })
  }

  async function postPedidoAuth(atendimento: any, empresa: any) {
    await api
      .post('/resultado-exames/pedido/auth', { atendimento, empresa })
      .then(res => {
        const parser = new DOMParser()
        const xmlResponse = parser.parseFromString(res.data, 'text/xml')
        const atendimentoResult = xmlResponse.querySelector(
          'ConsultaAtendimentoResult',
        )?.innerHTML
        const resultArray = atendimentoResult?.split('|')
        if (resultArray) {
          setUser(resultArray[0])
          setPassword(resultArray[1])
        }
      })
  }

  function handleCloseSnack() {
    setSnackOpen(false)
  }

  function handleSnackOpen() {
    setSnackOpen(true)
  }

  return (
    <ThemeProvider theme={themeEmpresa()}>
      <Container>
        <Content>
          <ImgContainer>
            <img src={logoEmpresa()} alt="Grupo Santa Joana" height={200} />
          </ImgContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h5>
              Digite o Número de Atendimento e Data de Nascimento para acessar
              seus Exames Laboratoriais:
            </h5>
            <Input
              icon={AiOutlineFieldNumber}
              name="atendimento"
              placeholder="Número do Atendimento"
              mask=""
              IconError={BiErrorCircle}
            />
            <Input
              icon={MdDateRange}
              name="nascimento"
              placeholder="Data de Nascimento"
              mask="99/99/9999"
              IconError={BiErrorCircle}
            />
            <Button type="submit">ACESSAR</Button>

            {errorMsg && (
              <ErrorContainer>
                <BiErrorCircle size={20} />
                <small>Campo Obrigatório</small>
              </ErrorContainer>
            )}

            {spinner && (
              <Spinner>
                <BiLoaderAlt className="logo-spin" />
                <h4>Aguarde...</h4>
              </Spinner>
            )}
          </Form>
        </Content>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackOpen}
          onClose={handleCloseSnack}
          message="Copiado"
          autoHideDuration={500}
          action={
            <button
              onClick={handleCloseSnack}
              style={{
                background: 'transparent',
                color: 'white',
                border: 'none',
                marginRight: '8px',
              }}
            >
              X
            </button>
          }
        />

        <BgRight />
        {showHeader ? (
          <HeaderDialog
            openSnack={handleSnackOpen}
            close={handleHeaderDialog}
            user={user}
            password={password}
          />
        ) : (
          ''
        )}

        <form
          action="https://sislu.dasa.com.br/sislu/slh/laudo.do"
          method="POST"
          target="_blank"
          ref={formRef2}
          name="form2"
          id="form2"
        >
          <input
            id="atendimento"
            name="atendimento"
            type="hidden"
            value={formValue}
          />
          <input id="chave" name="chave" type="hidden" value="UnYcfUVlSU" />
        </form>
        <DialogMaterial open={openDialog} onClose={handleCloseDialog}>
          <Button style={{ width: '80px' }} onClick={handleCloseDialog}>
            OK
          </Button>
        </DialogMaterial>
        {/* {showHeader ? (
          <IFrameContainer>
            <Iframe
              url={frameUrl()}
              position="absolute"
              width="100%"
              id="myId"
              className="myClassname"
              height="92%"
              styles={{ bottom: 0 }}
            />
          </IFrameContainer>
        ) : (
          ''
        )} */}
      </Container>
    </ThemeProvider>
  )
}
