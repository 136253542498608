export default {
  title: 'hmsj',

  colors: {
    blue: '#0079C7',
    light_blue: '#00A1FF',
    purple: '#2E258B',
    black: '#1b1b1b',
    red: '#c53040',
  },
}
