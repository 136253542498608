import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useField } from '@unform/core'
import InputMask from 'react-input-mask'
import { Container } from './styles'
import { IconBaseProps } from 'react-icons'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  mask: string
  icon?: React.ComponentType<IconBaseProps>
  IconError?: React.ComponentType<IconBaseProps>
}

const Input: React.FC<InputProps> = ({
  name,
  mask,
  icon: Icon,
  children,
  IconError,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)
    setIsFilled(!!inputRef.current?.value)
  }, [])

  const { registerField, defaultValue, fieldName, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [registerField, fieldName])

  return (
    <Container isFocused={isFocused} isFilled={isFilled} isErrored={!!error}>
      {Icon && <Icon size={20} className="s-icon" />}
      <InputMask
        {...rest}
        mask={mask}
        defaultValue={defaultValue}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        className="left-input-icon"
      >
        {() => <input {...rest} ref={inputRef} />}
      </InputMask>
      {IconError && <IconError size={20} className="error-icon" />}
    </Container>
  )
}

export default Input
