import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Main } from '../pages/Main'

export const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Main} />
    </Switch>
  )
}
